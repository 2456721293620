html, body, #root, #root>div.login-form, #root>div.setting-up-screen {
  height: 100%;
  overscroll-behavior: none;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(0,0,0,.5);
  -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}

.scrollable {
  display: block!important;
  overflow: auto;
  height: 200px;
}


.content {
  flex-grow: 1;
  display:flex;
  flex-direction: column;
}

.footer{
  flex-grow: 0;
}

.help-opened, .help-closed{
  display:flex;
  flex-grow: 1;
  flex-direction: column;
}

.filesystem-content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.filesystem-content > * {
  flex-grow: 0;
}

#uid-helper-text {
  width: max-content;
}

#gid-helper-text {
  width: max-content;
}

.multiselectCheckboxCell {
  display: none; /* TODO: show the select checkbox when multi-download performance is fixed */
  width: 32px;
  padding: 0 !important;
}

@media only screen and (max-width: 635px) {
  #uid-helper-text {
    width: inherit;
  }

  #gid-helper-text {
    width: inherit;
  }
}

input[type=file]::-webkit-file-upload-button {
  display: none;
}
